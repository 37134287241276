export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    header: 'User Content',
  },
  {
    title: 'Users',
    route: 'user.list',
    icon: 'UsersIcon',
  },
  {
    title: 'Organizations',
    route: 'organization.list',
    icon: 'FileIcon',
  },
  {
    title: 'Establishments',
    route: 'establishment.list',
    icon: 'FileIcon',
  },
  {
    title: 'Facilities',
    route: 'facility.list',
    icon: 'FileIcon',
  },
  {
    title: 'Teams',
    route: 'team.list',
    icon: 'FileIcon',
  },
  {
    title: 'Courses',
    route: 'course.list',
    icon: 'FileIcon',
  },
  {
    title: 'Course dates',
    route: 'course-date.list',
    icon: 'FileIcon',
  },
  {
    title: 'Events',
    route: 'event.list',
    icon: 'FileIcon',
  },
  {
    title: 'Feedbacks',
    route: 'feedback.list',
    icon: 'FileIcon',
  },
  {
    header: 'Admin Content',
  },
  {
    title: 'Invitations',
    route: 'invitation.list',
    icon: 'UsersIcon',
  },
  {
    title: 'News',
    route: 'news.list',
    icon: 'FileIcon',
  },
  {
    title: 'FAQs',
    route: 'faq.list',
    icon: 'FileIcon',
  },
  {
    title: 'Sport categories',
    route: 'sport-category.list',
    icon: 'FileIcon',
  },
  {
    header: 'System',
  },
  {
    title: 'Migrations',
    route: 'migration.list',
    icon: 'FileIcon',
  },
  {
    title: 'Roles & Permissions',
    route: 'roles-permissions',
    icon: 'FileIcon',
  },
  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
  },
]
